import React, { Component, useEffect, useReducer, useState } from 'react';
import {
  useLocation, useNavigate, useParams
} from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import AlertComponent from '../alertcomponent.js';

const SiteContactComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "Contact";

	//REACT HOOK FORM INIT
	const { control, handleSubmit, register, reset, formState: { errors } } = useForm({
		mode: "onChange",
    	defaultValues:
		{
		}
	});

	const [state, setState] = useState({
			showAlert: false,
			responseStatus: false,
			responseMessage: "",
			apiRequestLoadOK: false,
			isPageLoaded: false,
			isAPIFailure: false,
			formSubmitted: false
		});

	const navigate = useNavigate();

	useEffect(() => {


	}, []);

	const getFormValidStatus = (e) => {
		return formState.isFormValid;
	}

	const onSubmit = async (values) => {

		let data = {
        "name": values.name,
        "email": values.email,
        "message": values.message
		}

		const httpClient = new HttpClient();

		try
		{
			const responseData = await httpClient.sendPost('/sendmail.php', data);
			//responseData.data is the TEXT STRING. "OK" or "FAIL"
			//console.log("responseData.data", responseData.data);

			//IF DID NOT SUBMIT PROPERLY
			if (responseData.data.status !== undefined && !responseData.data.status) {
				setState({...state, showAlert: true, responseStatus: false, responseMessage: `Error: ${responseData.data.message}`});
				//window.scrollTo(0, 0);
			}
			else {
				setState({...state, showAlert: true, responseStatus: true, formSubmitted: true, responseMessage: `${responseData.data.message}`});
				//window.scrollTo(0, 0);
			}

		}
		catch ( error )
		{
			setState({...state, showAlert: true, responseStatus: false, responseMessage: "Failed to Process E=" + error});
			//window.scrollTo(0, 0);

			return false;
		};

		setState({...state, formSubmitted: true});
	}


	return (
<div className="container"> 

	<div className="row PageHead">
		<div className="col-md-12">
			<h1>Contact Us</h1>
			<h3>Keep in touch with us. we are here to help you</h3>
		</div>
	</div>

	<div className="row ContactUs">
		{state.formSubmitted && (
		<div>
			<h2>Message Sent!</h2>

			<div className="bg-light border">
				Your message has been sent! We will contact you shortly
			</div>
		</div>
		)}

		{!state.formSubmitted && (
		<div className="col-md-12">
			<form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>

			<div className="form-group">
				<label htmlFor="x" className="col-sm-2 control-label">Name</label>

				<div className="col-md-4">
					<input type="text" className="form-control" placeholder="Full Name"
					{...register("name", {
						required: "The name field must be between 4 and 255 characters!",
						minLength: 4,
						maxLength: 255
					})}
					/>

					{errors.name && (
					<p className="text-danger">
					{errors.name.message}
					</p>
					)}
				</div>      
			</div>

			<div className="form-group">
				<label htmlFor="x" className="col-sm-2 control-label">Email</label>

				<div className="col-md-4">
					<input type="text" className="form-control" placeholder="Email Address"
					{...register("email", {
						required: "A valid Email Address is required",
						pattern: {
							value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
							message: "A valid Email Address is required"
						}
					})}
					/>

					{errors.email && (
					<p className="text-danger">
				    	{errors.email.message}
					</p>
					)}
				</div>
			</div>

			<div className="form-group">
				<label htmlFor="x" className="col-sm-2 control-label">Message</label>

				<div className="col-md-4">
					<textarea rows="10" className="form-control" id="message" placeholder="Your Message" 
					name="message"
					{...register("message", {
						required: "The message field must be between 4 and 1024 characters!",
						minLength: 4,
						maxLength: 1024
					})}
					></textarea>

					{errors.message && (
					<p className="text-danger">
				    	{errors.message.message}
					</p>
					)}
				</div>
			</div>

			<div className="form-group">
				<p>
					<input type="submit" value="Send Message" className="btn btn-success btn-lg" />
				</p>
				<AlertComponent showAlert={state.showAlert} messageType={state.responseStatus} messageText={state.responseMessage} />
			</div>

		</form>
		</div>
		)}

	</div>

</div>
    );
};

export default SiteContactComponent;
