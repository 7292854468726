import React from 'react';

const SitePropertiesComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "Properties";

	return (
	<div className="container">

		<div className="row PageHead" id="testimonials">
			<div className="col-md-12">
				<h1>IronHostGroup Properties</h1>
				<h3>A List of our various Internet Properties</h3>
			</div>
		</div>

		<div className="row">
			<div className="col-sm-6 features">
				<h4>HostPallet.com</h4>
				<p>A high quality Shared/Reseller Hosting Provider using the latest standard software to provide 
				reliable hosting. HostPallet.com offers cPanel, DirectAdmin shared/reseler plans on high end hardware.</p>
				
				<a href='https://hostpallet.com'><strong>HostPallet.com</strong></a>
			</div>

			<div className="col-sm-6 features">
				<h4>InstanceDeploy.com</h4>
				<p>InstanceDeploy.com offers both managed and unmanaged VPS plans.</p>

				<a href='https://instancedeploy.com'><strong>InstanceDeploy.com</strong></a>
			</div>
		</div>

	</div>
	);

};

export default SitePropertiesComponent;
