import {
	useLocation,
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

//HEADER + FOOTER + MISC
import SiteHeaderComponent from './pages/siteheader.js';
import SiteFooterComponent from './pages/sitefooter.js';
import ProtectedRoute from './helpers/protectedroute.js';
//import SiteComponent from './pages/sitecomponent.js';

//AUTH
import AuthEmailVerifyComponent from './pages/auth/authemailverify.js';
//import AuthPhoneVerifyComponent from './pages/auth/authphoneverify.js';
import AuthRegisterComponent from './pages/auth/authregister.js';
import AuthLoginComponent from './pages/auth/authlogin.js';
import AuthLogoutComponent from './pages/auth/authlogout.js';

//SITE
import SiteIndexComponent from './pages/site/siteindex.js';
import SiteContactComponent from './pages/site/sitecontact.js';
import SiteAboutComponent from './pages/site/siteabout.js';
import SitePropertiesComponent from './pages/site/siteproperties.js';


const NoMatchComponent = () => {
  return (
    <div>
      <h2>404!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
};

function App() {

	return (
	<div className="App">
		<Router>
			<SiteHeaderComponent />

			<Routes>

				<Route path="/auth">
					<Route path="email_verify" element={<AuthEmailVerifyComponent />} />
					<Route path="register" element={<AuthRegisterComponent />} />
					<Route path="login" element={<AuthLoginComponent />} />
					<Route path="logout" element={<AuthLogoutComponent />} />
				</Route>

				<Route path="about">
					<Route index element={<SiteAboutComponent />} />
				</Route>

				<Route path={`properties`} element={<SitePropertiesComponent />}></Route>
				<Route path={`contact`} element={<SiteContactComponent />}></Route>

				<Route exact path="/" element={<SiteIndexComponent />}></Route>
				<Route path="*" element={<NoMatchComponent />}></Route>

			</Routes>
			<SiteFooterComponent />

		</Router>
	</div>
	);
}

export default App;
