import React from 'react';

const SiteFooterComponent = (props) => {

	return (
	<div className="footer">
		<div className="container">
			<div className="row copyright">
				<div className="pull-right">
					
				</div>
				<p>&copy; 2023-2024. IronHostGroup.com. All Rights Reserved.</p>
			</div>
		</div>
	</div>
	);

};

export default SiteFooterComponent;
