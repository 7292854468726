import React, { Component } from 'react';
import {
  Outlet,
} from "react-router-dom";

const SiteIndexComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "Home";

/*
		<div className="jumbotron masthead">
			<div className="container"> 
				<div className="flexslider">
					<ul className="slides">
						<li>
							<div className="hero-unit">
								<h1>Affordable Hosting Solution</h1>
								<h3>Hosting for sites of all sizes at affordable pricing.</h3>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>

*/

	return (
	<div>

		<div className="container"> 
			<div className="row mainFeatures" id="features">
				<div className="col-sm-6 col-md-4">
					<div className="img-thumbnail">
						<img src="/assets/SiteTheme/images/secure_img.png" width="85" height="88" alt="secure" />
					
						<div className="caption">
							<h4>Secure & Reliable</h4>
							<p>All of our services and servers are secured in locked cabinets. 
							Each machine has been hardened with custom firewall rules, selinux policies and monitored using our custom software.
							You can be rest assured that your data will be secured with us.</p>
						</div>
					</div>
				</div>

				<div className="col-sm-6 col-md-4">
					<div className="img-thumbnail">
						<img src="/assets/SiteTheme/images/fast_img.png" width="85" height="88" alt="secure" />

						<div className="caption">
							<h4>Super Fast</h4>
							<p>Our network contains many different providers to ensure the best route is taken to your ISP.
								Reach your users in milliseconds, not seconds.</p>
						</div>
					</div>
				</div>

				<div className="col-sm-6 col-md-4 col-sm-offset-3 col-md-offset-0">
					<div className="img-thumbnail">
						<img src="/assets/SiteTheme/images/support_img.png" width="85" height="88" alt="secure" />
					
						<div className="caption">
							<h4>Customer Support</h4>
							<p>All of our various properties have Sales and Support teams waiting to assist you 24/7.
							</p>
						</div>
					</div>
				</div>
			</div>
		  


			<div className="row PageHead">
				<div className="col-md-12">
					<h1>IronHostGroup Features</h1>
					<h3>Our features allow us to stand out from our competitors.</h3>
				</div>
			</div>

			<div className="row">
				<div className="col-sm-6 features">
					<img src="/assets/SiteTheme/images/setup_icon.png" alt="icon" className="img-responsive" />
					<h4>Instant Setup</h4>
					<p>All across our various internet properties, setup is instant and you can get up and running right away.
						If you sign up for hosting your account will be approved and activated right away. Our web cloud protection service
						is automatically configured for most websites, and is functional right away. Never wait again.
					</p>
				</div>

				<div className="col-sm-6 features">
					<img src="/assets/SiteTheme/images/backup_icon.png" alt="icon" className="img-responsive" />
					<h4>Constant Backups</h4>
					<p>All of our internet properties have our own custom backup software running.
						Your data is exported to remote backup machines, and remains safe should our production machines experience problems.
						Our backup software is designed to easily import into our various internet properties to minimize any potential downtime.
					</p>
				</div>
			</div>

			<div className="row">
				<div className="col-sm-6 features">
					<img src="/assets/SiteTheme/images/git_icon.png" alt="icon" className="img-responsive" />
					<h4>Health Checkups</h4>
					<p>We have a number of internet properties, ranging from hosting, cloud and web protection systems.
						Each of these services have multiple machines behind them. We have built custom monitoring scripts,
						checking the major services on each machine, locally and remotely to determine if a machine is responding,
						or encountering issues and taking corrective action if needed.</p>
				</div>

				<div className="col-sm-6 features">
					<img src="/assets/SiteTheme/images/script_icon.png" alt="icon" className="img-responsive" />
					<h4>Reliable Support</h4>
					<p>No matter how smart you are, at some time or another you will need support. Whether your issues range from
						figuring out how to add an email account, determine why your WordPress site is not connecting to your database,
						trying to add your site to one of our cloud platforms, or configuring web protection rules, we can help.
						Our staff are trained to assist you with any issue you may face.</p>
				</div>
			</div>
		</div>

	</div>
	);

};

export default SiteIndexComponent;
