import React, { useReducer, useEffect, useState } from 'react';
import {
  useLocation, useNavigate, useParams
} from "react-router-dom";

import AlertComponent from '../alertcomponent.js';
import AuthenticationService from '../../services/authenticationservice.js';

const AuthLogoutComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "Logout";

	const authenticationService = new AuthenticationService();
	const navigate = useNavigate();

	useEffect(() => {
		if(authenticationService.getLoginStatus())
		{
			authenticationService.logout();
			//navigate('/');
			window.location.href="/auth/login";
		}
		else
		{
			//authenticationService.logout();
		}
		//navigate('/');
	}, []);

    return (
<div className="container">
</div>
    );

};

export default AuthLogoutComponent;
