import React from 'react';

const SiteAboutComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "About";

	return (
	<div className="container"> 

		<div className="row PageHead">
			<div className="col-md-12">
				<h1>About</h1>
				<h3>Who are we?</h3>
			</div>
		</div>

		<div className="row">

			<strong>Our Background</strong>
			<br />
			We are technical support specialists, software developers and sales/business representatives that have come together,
			after working for larger and more established companies to provide customers with better service, reliability and functionality.
			<br /><br />
			Many of our larger competitors have grown complicit, and do not provide anything unique. 
			We have our own software developers constantly working on new features such as our snapshot/restore functionality for hosting.
			In addition we have our own service to filter web application attacks, and easily scale your site without in depth technical knowledge.
			<br /><br />
			Our hosting service is designed to bring reliability to the industry, against a field of competitors who use the same standard software, 
			competing on price, loading machines up with accounts until they no longer respond. 
			Our cloud service is designed to compete against Azure/AWS, providing everyone with the ability to build their own custom 
			clouds for their website without any knowledge.

			<br /><br />


			<strong>Our Goal</strong>
			<br />
			Our goal is simple. Build reliable services while providing great customer support to our clients.
			<br /><br /> 
			Our plan is to slowly build a collection of internet services related to hosting, 
			cloud hosting, cloud security, dns/mail/api management, and software production deployment.
			<br /><br />
			Each of our services is designed for a specific purpose, from hosting to the general public, 
			software deployment tools for developers to web protection systems keeping our customers safe.
			<br /><br />
			Each month our developers build additional functionality into our backend systems to improve our services functionality and performance.


			<br />
		</div>

	</div>
	);

};

export default SiteAboutComponent;
