import React, { useReducer, useEffect, useState } from 'react';
import {
  useLocation, useNavigate, useParams
} from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import AlertComponent from '../alertcomponent.js';
import AuthenticationService from '../../services/authenticationservice.js';
import HttpClient from '../../helpers/httpclient.js';

const AuthEmailVerifyComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "Email Verify";

	//REACT HOOK FORM INIT
	const { control, handleSubmit, register, reset, formState: { errors } } = useForm({
		mode: "onChange",
    	defaultValues:
		{
		}
	});

	const [state, setState] = useState({
			showAlert: false,
			responseStatus: false,
			responseMessage: "",
			apiRequestLoadOK: false,
			isPageLoaded: false,
			isAPIFailure: false
		});
	const navigate = useNavigate();
	const params = useParams();
	let emailString = "";
	let codeString = "";

	const authenticationService = new AuthenticationService();

	useEffect(() => {

		const fetchData = async () => {

			//GET ID VALUES
			const searchParams = new URLSearchParams(location.search);
			emailString = searchParams.get('email');
			codeString = searchParams.get('code');

			//DEFAULT VALUES
			reset({
				email: emailString,
				verifycode: codeString
			});

			//UPDATE STATE
			setState({...state, 
				apiRequestLoadOK: true,
				isPageLoaded: true,
				isAPIFailure: false
			});

		};
		fetchData();

	}, []);

	const getFormValidStatus = (e) => {
		return formState.isFormValid;
	}

	const onSubmit = async (values) => {

		//IF FORM STATUS IS INVALID
		if( !getFormValidStatus() )
		{
			displayValidationErrors(formState, dispatch);
			setState({...state, showAlert: true, responseStatus: false, responseMessage: "Please fill out all required fields!"});
			window.scrollTo(0, 0);
			return false;
		}

		let data = {
        "email": values.email,
		"verifyCode": values.verifycode
		}

		const httpClient = new HttpClient();

		try
		{
			const responseData = await httpClient.sendPost('/api/Auth/EmailVerification', data);

			//IF DID NOT SUBMIT PROPERLY
			if (responseData.data.status !== undefined && !responseData.data.status) {
				setState({...state, showAlert: true, responseStatus: false, responseMessage: `Error: ${responseData.data.message}`});
				window.scrollTo(0, 0);
			}
			else {
				navigate(`/auth/login`);
			}

		}
		catch ( error )
		{
			setState({...state, showAlert: true, responseStatus: false, responseMessage: "Failed to Send Request!"});
			window.scrollTo(0, 0);

			return false;
		};
	}

	if (!state.isPageLoaded && !state.isAPIFailure)
	{
		return (<div><img id="loader-img" alt="" src="/assets/SiteTheme/images/ajax-loader.gif" width="200" height="200" /></div>);
	}

	if (state.isPageLoaded && !state.apiRequestLoadOK)
	{
		return (
		<div className="container">
			<AlertComponent showAlert={state.showAlert} messageType={state.responseStatus} messageText={state.responseMessage} />
		</div>
		);
	}

    return (

<div className="container">
  <AlertComponent showAlert={state.showAlert} messageType={state.responseStatus} messageText={state.responseMessage} />

  <div className="bg-light border">
	<h2>Email Verification!</h2>

    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
      <div className="col-md-4">

        <div className="mb-3">
          <label htmlFor="email" className="col-md-2 control-label"><strong>Email</strong></label>

			<input type="text"
			{...register("email", {
				required: "A valid Email Address is required",
				pattern: {
					value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
					message: "A valid Email Address is required"
				}
			})}
			/>

			{errors.email && (
			<p className="text-danger">
            	{errors.email.message}
			</p>
			)}
        </div>
      </div>

      <div className="col-md-4">
        <div className="mb-3">
          <label htmlFor="verifycode" className="col-md-5 control-label"><strong>Verification Code</strong></label>

			<input type="text"
			{...register("verifycode", {
				required: "The verification code field must be between 4 and 255 characters!",
				minLength: 4,
				maxLength: 255
			})}
			/>

			{errors.name && (
			<p className="text-danger">
			{errors.name.message}
			</p>
			)}
        </div>
      </div>

      <button type="submit" className="btn btn-primary">Verify Email</button>
	  <br />


    </form>

  </div>
</div>
    );

};

export default AuthEmailVerifyComponent;
