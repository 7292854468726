import React, { useReducer, useEffect, useMemo, useState } from 'react';
import {
  useLocation, useNavigate, useParams
} from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import AlertComponent from '../alertcomponent.js';
import AuthenticationService from '../../services/authenticationservice.js';
import HttpClient from '../../helpers/httpclient.js';

const AuthRegisterComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "Register";

	//REACT HOOK FORM INIT
	const { control, handleSubmit, register, reset, formState: { errors } } = useForm({
		mode: "onChange",
    	defaultValues:
		{
		}
	});

	const [state, setState] = useState({
			showAlert: false,
			responseStatus: false,
			responseMessage: "",
			apiRequestLoadOK: false,
			isPageLoaded: false,
			isAPIFailure: false,
			defaultValue_country: "",
			countryCodeList: [],
			secretQuestionList: []
		});
	const navigate = useNavigate();

	const authenticationService = new AuthenticationService();

	useEffect(() => {

		const fetchData = async () => {

			const httpClient = new HttpClient();

			try
			{
				const responseData = await httpClient.sendGet('/api/Auth/Register');

				if(responseData.data.status !== undefined && !responseData.data.status && responseData.data.message !== undefined)
				{
					//UPDATE STATE
					setState({...state, 
						apiRequestLoadOK: false,
						isPageLoaded: true,
						isAPIFailure: true,
						showAlert: true,
						responseStatus: responseData.data.status,
						responseMessage: responseData.data.message
						});
				}
				else
				{
					//UPDATE STATE
					setState({...state, 
						apiRequestLoadOK: true,
						isPageLoaded: true,
						isAPIFailure: false,
						countryCodeList: responseData.data.countryCodeList, 
						secretQuestionList: responseData.data.secretQuestionList,
						defaultValue_country: responseData.data.countryCode
						});
				}

			}
			catch ( error )
			{
				setState({...state, apiRequestLoadOK: false, isPageLoaded: true, isAPIFailure: true, showAlert: true, responseStatus: false, responseMessage: `Error: Status=${error.status} => ${error.message}`});
				window.scrollTo(0, 0);

				return false;
			};

		};
		fetchData();

		/*
		const responseData = {
			"country": "US",
			"countryCodeList": [{"text": "United Kingdom", "value": "UK"}, {"text": "United States", "value": "US"}], 
			"secretQuestionList": [{"text": "SEQ 1", "value": "s1"}, {"text": "SEQ 2", "value": "s2"}]
		};

		let tempCountryCodeList = []
		let tempSecretQuestionList = []

		responseData.countryCodeList.map( (item, index) => {
			tempCountryCodeList.push(item);
			return true;
		});

		responseData.secretQuestionList.map( (item, index) => {
			tempSecretQuestionList.push(item);
			return true;
		});

		//UPDATE STATE
		setState({...state, countryCodeList: tempCountryCodeList, secretQuestionList: tempSecretQuestionList});
		setUserInput("country", responseData.country, sendProps);
		*/

	}, []);

	const onSubmit = async (values) => {

		let data = {
        "email": values.email,
        "password": values.password,
        "confirmPassword": values.confirmPassword,
        "firstName": values.firstName,
        "middleName": values.middleName,
        "lastName": values.lastName,
        "company": values.company,
        "address1": values.address1,
        "address2": values.address2,
        "cityCode": fvalues.cityCode,
        "state": values.state,
        "zipCode": values.zipCode,
        "phoneNumber": values.phoneNumber,
        "country": values.country,
        "secretQuestionID": values.secretQuestionID,
        "secretAnswer": values.secretAnswer
		}

		const httpClient = new HttpClient();

		try
		{
			const responseData = await httpClient.sendPost('/api/Auth/Register', data);

			//IF DID NOT SUBMIT PROPERLY
			if (responseData.data.status !== undefined && !responseData.data.status) {
				setState({...state, showAlert: true, responseStatus: false, responseMessage: `Error: ${responseData.data.message}`});
				window.scrollTo(0, 0);
			}
			else {

				if(responseData.data.redirectUrl === "email_verify")
				{
					navigate("/auth/email_verify?email=" + formState.email.value);
				}
				else
				{
					navigate("/auth/login");
				}

				//navigate('/x/' + this.numberMask);
			}

		}
		catch ( error )
		{
			//let errorJson = error.toJSON();
			//console.log("JSON ERROR", errorJson);
			setState({...state, showAlert: true, responseStatus: false, responseMessage: error.message});
			window.scrollTo(0, 0);

			return false;
		};

	}

	if (!state.isPageLoaded && !state.isAPIFailure)
	{
		return (<div><img id="loader-img" alt="" src="/assets/SiteTheme/images/ajax-loader.gif" width="200" height="200" /></div>);
	}

    return (
<div className="container">
	<AlertComponent showAlert={state.showAlert} messageType={state.responseStatus} messageText={state.responseMessage} />

  <div className="bg-light border">
    <h4>Register an account with us!</h4>

    <form className="form-horizontal" id="form1" onSubmit={handleSubmit(onSubmit)}>

	<div className="form-group">
		<label htmlFor="email" className="col-md-2 control-label">Email</label>

        <div className="col-sm-4">
			<input type="text" className="form-control"
			{...register("email", {
				required: "A valid Email Address is required",
				pattern: {
					value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
					message: "A valid Email Address is required"
				}
			})}
			/>

			{errors.email && (
			<p className="text-danger">
            	{errors.email.message}
			</p>
			)}
        </div>
	</div>

	<div className="form-group">
		<label htmlFor="password" className="col-md-2 control-label">Password</label>

        <div className="col-sm-4">
			<input type="password" className="form-control"
			{...register("password", {
				required: "The password field must be between 4 and 255 characters!",
				minLength: 4,
				maxLength: 255
			})}
			/>

			{errors.password && (
			<p className="text-danger">
			{errors.password.message}
			</p>
			)}
		</div>
	</div>

	<div className="form-group">
        <label htmlFor="confirmPassword" className="col-md-2 control-label">Confirm Password</label>

		<div className="col-sm-4">
			<input type="password" className="form-control"
			{...register("confirmPassword", {
				required: "The confirm password field must be between 4 and 255 characters!",
				minLength: 4,
				maxLength: 255
			})}
			/>

			{errors.password && (
			<p className="text-danger">
			{errors.password.message}
			</p>
			)}
		</div>
	</div>

	<div className="form-group">
        <label htmlFor="firstName" className="col-md-2 control-label">First Name</label>

		<div className="col-sm-4">
			<input type="text" className="form-control"
			{...register("firstName", {
				required: "This field must be between 4 and 255 characters!",
				minLength: 4,
				maxLength: 255
			})}
			/>

			{errors.firstName && (
			<p className="text-danger">
			{errors.firstName.message}
			</p>
			)}
		</div>
	</div>

	<div className="form-group">
        <label htmlFor="middleName" className="col-md-2 control-label">Middle Name</label>

		<div className="col-sm-4">
			<input type="text" className="form-control"
			{...register("middleName", {
				required: false,
				minLength: { value: 4, message: "Must be at least 4 characters!" },
				maxLength: { value: 255, message: "Must be less than 255 characters!"}
			})}
			/>

			{errors.middleName && (
			<p className="text-danger">
			{errors.middleName.message}
			</p>
			)}
		</div>
	</div>

	<div className="form-group">
        <label htmlFor="firstName" className="col-md-2 control-label">Last Name</label>

        <div className="col-sm-4">
			<input type="text" className="form-control"
			{...register("lastName", {
				required: "This field must be between 4 and 255 characters!",
				minLength: 4,
				maxLength: 255
			})}
			/>

			{errors.lastName && (
			<p className="text-danger">
			{errors.lastName.message}
			</p>
			)}
		</div>
	</div>

	<div className="form-group">
        <label htmlFor="company" className="col-md-2 control-label">Company</label>

        <div className="col-sm-4">
			<input type="text" className="form-control"
			{...register("company", {
				required: false,
				minLength: { value: 4, message: "Must be at least 4 characters!" },
				maxLength: { value: 255, message: "Must be less than 255 characters!"}
			})}
			/>

			{errors.company && (
			<p className="text-danger">
			{errors.company.message}
			</p>
			)}
		</div>
	</div>

	<div className="form-group">
		<label htmlFor="address1" className="col-md-2 control-label">Address 1</label>

		<div className="col-sm-4">
			<input type="text" className="form-control"
			{...register("address1", {
				required: "This field must be between 4 and 255 characters!",
				minLength: 4,
				maxLength: 255
			})}
			/>

			{errors.address1 && (
			<p className="text-danger">
			{errors.address1.message}
			</p>
			)}
		</div>
	</div>

	<div className="form-group">
        <label htmlFor="address2" className="col-md-2 control-label">Address 2</label>

        <div className="col-sm-4">
			<input type="text" className="form-control"
			{...register("address2", {
				required: false,
				minLength: { value: 4, message: "Must be at least 4 characters!" },
				maxLength: { value: 255, message: "Must be less than 255 characters!"}
			})}
			/>

			{errors.address2 && (
			<p className="text-danger">
			ERROR {errors.address2.message}
			</p>
			)}
        </div>
	</div>

	<div className="form-group">
        <label htmlFor="cityCode" className="col-md-2 control-label">City</label>

        <div className="col-sm-4">
			<input type="text" className="form-control"
			{...register("cityCode", {
				required: "This field must be between 4 and 255 characters!",
				minLength: 4,
				maxLength: 255
			})}
			/>

			{errors.cityCode && (
			<p className="text-danger">
			{errors.cityCode.message}
			</p>
			)}
		</div>
	</div>

	<div className="form-group">
        <label htmlFor="state" className="col-md-2 control-label">State</label>

        <div className="col-sm-4">
			<input type="text" className="form-control"
			{...register("state", {
				required: "This field must be between 4 and 255 characters!",
				minLength: 4,
				maxLength: 255
			})}
			/>

			{errors.state && (
			<p className="text-danger">
			{errors.state.message}
			</p>
			)}
		</div>
	</div>

	<div className="form-group">
        <label htmlFor="zipCode" className="col-md-2 control-label">Zip Code</label>

        <div className="col-sm-4">
			<input type="text" className="form-control"
			{...register("zipCode", {
				required: "This field must be between 4 and 25 characters!",
				minLength: 4,
				maxLength: 25
			})}
			/>

			{errors.zipCode && (
			<p className="text-danger">
			{errors.zipCode.message}
			</p>
			)}
		</div>
	</div>

	<div className="form-group">
        <label htmlFor="country" className="col-md-2 control-label">Country</label>

        <div className="col-sm-4">
			<select className="form-select" value={state.defaultValue_country}
				{
				...register("country", {
					required: true
				})}
				>
            	<option value="">--Select--</option>
				{state.countryCodeList.map((s, index) => (
				<option key={`s-${index}`} value={s.value}>{s.text}</option>
				))}
			</select>

			{errors.country?.type === "required" && (
			<p className="text-danger">
            Please select a country!
			</p>
			)}
 		</div>
	</div>

	<h2>Phone Information</h2>
	<div className="form-group">
        <label htmlFor="phoneNumber" className="col-md-2 control-label">Phone Number</label>

        <div className="col-sm-4">
			<input type="text" className="form-control"
			{...register("phoneNumber", {
				required: "This field must be between 4 and 32 characters!",
				minLength: 4,
				maxLength: 32
			})}
			/>

			{errors.phoneNumber && (
			<p className="text-danger">
			{errors.phoneNumber.message}
			</p>
			)}
		</div>
	</div>

	<h2>Security Information</h2>
	<div className="form-group">
		<label htmlFor="secretQuestionID" className="col-md-2 control-label">Secret Question</label>

        <div className="col-sm-4">
			<select className="form-select"
				{
				...register("secretQuestionID", {
					required: true
				})}
				>
            	<option value="">--Select--</option>
				{state.secretQuestionList.map((s, index) => (
				<option key={`s-${index}`} value={s.value}>{s.text}</option>
				))}
			</select>

			{errors.secretQuestionID?.type === "required" && (
			<p className="text-danger">
            Please select a secret question!
			</p>
			)}
		</div>
	</div>

	<div className="form-group">
        <label htmlFor="secretAnswer" className="col-md-2 control-label">Secret Answer</label>

        <div className="col-sm-4">
			<input type="text" className="form-control"
			{...register("secretAnswer", {
				required: "This field must be between 4 and 255 characters!",
				minLength: 4,
				maxLength: 255
			})}
			/>

			{errors.secretAnswer && (
			<p className="text-danger">
			{errors.secretAnswer.message}
			</p>
			)}
		</div>
	</div>

	<div className="form-group">
        <label htmlFor="tosterms" className="col-md-2 control-label">TOS/AUP</label>

        <div className="col-sm-4">
			<input type="checkbox" className="control-label"
			{...register("tosterms", {
				required: true
			})}
			/>
			I agree to the TOS/AUP<br />

			{errors.tosterms?.type === "required" && (
			<p className="text-danger">
            	Please agree to the TOS/AUP to continue!
			</p>
			)}
		</div>
	</div>


	<div className="form-group">
		<div className="col-md-offset-2 col-md-10">
			<input type="submit" className="btn btn-primary" value="Register" />
		</div>
	</div>

    </form>

  </div>
</div>
    );

};

export default AuthRegisterComponent;
