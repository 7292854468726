import React, { useReducer, useState } from 'react';
import {
  useLocation, useNavigate, useParams
} from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import AlertComponent from '../alertcomponent.js';
import AuthenticationService from '../../services/authenticationservice.js';

const AuthLoginComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "Login";

	//REACT HOOK FORM INIT
	const { control, handleSubmit, register, reset, formState: { errors } } = useForm({
		mode: "onChange",
    	defaultValues:
		{
		}
	});

	const [state, setState] = useState({
			showAlert: false,
			responseStatus: false,
			responseMessage: "",
			apiRequestLoadOK: false,
			isPageLoaded: false,
			isAPIFailure: false
		});
	const navigate = useNavigate();

	const authenticationService = new AuthenticationService();

	const onSubmit = async (values) => {

		try
		{
			const response = await authenticationService.login(formState.email.value, formState.password.value);

			if(authenticationService.getLoginStatus())
			{
				setState({showAlert: false, responseStatus: true, responseMessage: ""});

				//DETERMINE REDIRECT LOCATION
				if (authenticationService.getLoginGroup() === "admin") {
					//navigate('/admin');
					window.location.href="/admin";
				}
				else if (authenticationService.getLoginGroup() === "user"
					|| authenticationService.getLoginGroup() === "other") {
					//navigate('/user');
					window.location.href="/user";
				}
				else {
					navigate('/');
				}

			}
			else
			{
				//console.log("LOGIN FAILURE", authenticationService.getLoginMessage());
				setState({showAlert: true, responseStatus: authenticationService.getLoginStatus(), 
					responseMessage: authenticationService.getLoginMessage()});
			}
		}
		catch (error)
		{
			setState({showAlert: true, responseStatus: authenticationService.getresponseStatus(), responseMessage: authenticationService.getresponseMessage()});
		}
	}


    return (

<div className="container">
  <AlertComponent showAlert={state.showAlert} messageType={state.responseStatus} messageText={state.responseMessage} />

  <div className="bg-light border">
	<h2>Please login below!</h2>

    <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
      <div className="col-md-4">

        <div className="mb-3">
          <label htmlFor="email" className="col-md-4 control-label"><strong>Email</strong></label>

			<input type="text" className=""
			{...register("email", {
				required: "A valid Email Address is required",
				pattern: {
					value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
					message: "A valid Email Address is required"
				}
			})}
			/>

			{errors.email && (
			<p className="text-danger">
            	{errors.email.message}
			</p>
			)}
        </div>
      </div>

      <div className="col-md-4">
        <div className="mb-3">
          <label htmlFor="password" className="col-md-4 control-label"><strong>Password</strong></label>

			<input type="password" className=""
			{...register("password", {
				required: false,
				minLength: 4,
				maxLength: 20
			})}
			/>

			{errors.password && (
			<p className="text-danger">
			{errors.password.message}
			</p>
			)}
        </div>
      </div>

      <div className="form-group">
        <label className="col-md-2 control-label" htmlFor="exampleCheck1"><strong>Remember Me</strong></label>

		<input type="checkbox" className="control-label"
			{...register("rememberme", {
				required: false
			})}
		/>

      </div>

      <button type="submit" className="btn btn-primary">Login</button>
	  <br />


    </form>

  </div>
</div>
    );

};

export default AuthLoginComponent;
