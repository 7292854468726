import React from 'react';
import {
  Link
} from "react-router-dom";

import AuthenticationService from '../services/authenticationservice.js';

const SiteHeaderComponent = (props) => {

	const authenticationService = new AuthenticationService();

	return (
	<nav className="navbar navbar-default navbar-fixed-top" role="navigation"> 

		<div className="container">
			<div className="navbar-header">
				<button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-ex1-collapse"> 
				<span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span> 
				<span className="icon-bar"></span> <span className="icon-bar"></span> 
				</button>

				<Link className="navbar-brand" to={`/`}><img src="/assets/SiteTheme/images/flathost-logo.png" alt="logo" /></Link>
			</div>

			<div className="collapse navbar-collapse navbar-ex1-collapse">
				<ul className="nav navbar-nav navbar-right">
					<li className="active"> <Link className="" to={`/`}>HOME</Link> </li>
					<li className="hidden-sm"> <Link className="" to={`/about`}>ABOUT</Link> </li>
					<li className="hidden-sm"> <Link className="" to={`/properties`}>PROPERTIES</Link> </li>
					<li> <Link className="" to={`/contact`}>CONTACT</Link> </li>
				</ul>
			</div>

		</div>
	</nav>
	);

};

export default SiteHeaderComponent;
